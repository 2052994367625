import { useState, useCallback } from 'react';
export function useBoolean(defaultValue) {
    var _a = useState(!!defaultValue), value = _a[0], setValue = _a[1];
    var onTrue = useCallback(function () {
        setValue(true);
    }, []);
    var onFalse = useCallback(function () {
        setValue(false);
    }, []);
    var onToggle = useCallback(function () {
        setValue(function (prev) { return !prev; });
    }, []);
    return {
        value: value,
        onTrue: onTrue,
        onFalse: onFalse,
        onToggle: onToggle,
        setValue: setValue,
    };
}
